import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  innerWidth,
  mainCyan,
  lightPurple,
  subFont,
  screen,
} from "../components/variables";
import listPurpleArrow from "../images/list-purple-arrow.png";

const Wrapper = styled.div`
  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .header {
    .heading {
      font-weight: 900;
      line-height: 1.22;
      max-width: 232px;
      position: relative;
      font-size: 2rem;
      @media ${screen.xxsmall} {
        font-size: 3.5rem;
        max-width: 1200px;
      }
      @media ${screen.small} {
        font-size: 5rem;
        line-height: 1.09;
      }
    }
  }

  .questions {
    max-width: 950px;
    margin: 38px auto 115px auto;
    @media ${screen.xxsmall} {
      margin: 75px auto 115px auto;
    }

    &__each {
      margin: 0 0 60px 0;
      @media ${screen.xxsmall} {
        margin: 0 0 50px 0;
      }

      p {
        margin: 0 0 21px 0;
        font-size: 1.02rem;
        @media ${screen.small} {
          font-size: 1.06rem;
        }
        &:last-child {
          margin: 0;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: 700;
        line-height: 1.4;
        margin: 0 0 16px 0;
        font-size: 1.25rem;
        @media ${screen.xxsmall} {
          font-size: 1.52rem;
          margin: 0 0 23px 0;
        }
        @media ${screen.small} {
          font-size: 1.55rem;
        }

        strong {
          text-decoration: underline;
          font-weight: 900;
        }
      }

      ul {
        margin: 20px 0;

        li {
          font-family: ${subFont};
          line-height: 1.42;
          position: relative;
          padding: 0 0 0 46px;
          margin: 0 0 20px 0;
          font-size: 1.15rem;
          text-transform: uppercase;
          @media ${screen.xxsmall} {
            width: 50%;
            display: inline-block;
            padding: 0 30px 0 55px;
            margin: 0 0 16px 0;
          }

          &::after {
            content: "";
            width: 40px;
            height: 40px;
            background-image: url(${listPurpleArrow});
            background-size: 26px;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            left: -5px;
            top: -7px;
            @media ${screen.xxsmall} {
              left: 0;
              top: -7px;
            }
          }
        }
      }
    }

    a {
      color: ${mainCyan};
      text-decoration: underline;
      @media ${screen.withCursor} {
        &:hover {
          color: ${lightPurple};
        }
      }
    }
  }
`;

const FaqsPage = ({ data }) => {
  const {
    heading,
    question_list,
    title_tag,
    meta_description,
  } = data.content.data;

  const pageURL = `https://www.k9swim.com.au/faqs/`;

  return (
    <Layout>
      <SEO
        title={title_tag || "FAQs"}
        description={meta_description}
        url={pageURL}
      />
      <Wrapper>
        <div className="inner-wrapper">
          <header className="header">
            <h1 className="heading">{heading.text}</h1>
          </header>

          <section className="questions">
            {question_list.map((item, i) => (
              <div
                data-sal="fade"
                data-sal-duration="700"
                className="questions__each"
                key={i}
                dangerouslySetInnerHTML={{ __html: item.text.html }}
              />
            ))}
          </section>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default FaqsPage;

export const dataQuery = graphql`
  {
    content: prismicFaqsPage {
      data {
        heading {
          text
        }
        question_list {
          text {
            html
          }
        }
        title_tag
        meta_description
      }
    }
  }
`;
